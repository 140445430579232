import { Utils } from '../../shared/helper/utils';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CanActivate, ActivatedRouteSnapshot, ActivatedRoute, CanLoad, Route, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '@core/services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(
        private authService: AuthService
    ) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.authService.isAuthenticated()) {
                resolve(true);
            }
            else {
                this.authService.logout_noauth(state.url);
                resolve(false);
            }
        });
    }
    canLoad(route: Route) {
        let returnUrl = (route.path == 'wizard') ? '/wizard' : '/dashboard/' + route.path;
        if (this.authService.isAuthenticated()) {
            return true;
        }
        else {
            this.authService.logout_noauth(returnUrl);
            return false
        }
    }
}
