import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
//import { ComingsoonComponent } from "@modules/home/pages/comingsoon/comingsoon.component";
import { EmailVerifiedComponent } from "@modules/home/pages/email-verified/email-verified.component";
import { NotFoundComponent } from "@shared/components/not-found/not-found.component";
import { GlobalCheckReminderComponent } from "@modules/dashboard/sections/reminders/global-check-reminder/global-check-reminder.component";
import { AuthGuard } from "@modules/auth/auth-guard";
import { PublicGuard } from "@modules/auth/public.guard";
import { ErrorPageComponent } from "@shared/components/error-page/error-page.component";

const routes: Routes = [
  { path: "", redirectTo: "auth/login", pathMatch: "full" },
  {
    path: "ViewNotification",
    component: GlobalCheckReminderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "dashboard",
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import("@modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "auth",
    canLoad: [PublicGuard],
    loadChildren: () =>
      import("@modules/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("@modules/admin/admin.module").then((m) => m.AdminModule),
  },
  // {
  //   path: "wizard",
  //   loadChildren: () =>
  //     import("@modules/wizard/wizard.module").then((m) => m.WizardModule),
  // },
  {
    path: "wizard",
    canLoad: [AuthGuard],
    loadChildren: () =>
      import("@modules/wizard1/wizard1.module").then((m) => m.Wizard1Module),

  },
  {
    path: "home",
    loadChildren: () =>
      import("@modules/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "page-not-found",
    component: NotFoundComponent,
  },
  {
    path: "error-page",
    component: ErrorPageComponent,
  },
  { path: 'email-verified', component: EmailVerifiedComponent },
  { path: "**", redirectTo: "page-not-found", pathMatch: "full" }
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  imports: [[RouterModule.forRoot(routes, {
    useHash: true,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    // relativeLinkResolution: 'legacy'
  })],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
